import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../common/services/auth.service';

@Component({
    selector: 'app-component',
    template: `
        <div class="container-fluid h-100">
            <div class="row align-items-center h-100">
                <div class="offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xxl-4 col-xxl-4">
                    <app-panel class="p-5">
                        <router-outlet />
                    </app-panel>
                </div>
            </div>
        </div>
        <app-analytics />
    `,
})
export class AppComponent implements OnInit {
    ngOnInit(): void {
        this.auth.redirectToApp();
    }

    constructor(private auth: AuthService) {}
}
