import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../common/environment/environment';
import { ENVIRONMENT } from '../../../common/environment/environment.config';
import { AnalyticsModule } from '../../../common/modules/analytics/analytics.module';
import { AlertModule } from '../../../common/modules/bootstrap/alert/alert.module';
import { FontAwesomeModule } from '../../../common/modules/font-awesome/font-awesome.module';
import { InputPasswordModule } from '../../../common/modules/form/input-password/input-password.module';
import { NgbControlModule } from '../../../common/modules/form/ngb-control/ngb-control.module';
import { PanelModule } from '../../../common/modules/panel/panel.module';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

@NgModule({
    declarations: [
        AppComponent,
        ChangePasswordComponent,
        LoginComponent,
        ResetPasswordComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        AnalyticsModule,
        BrowserModule,
        HttpClientModule,
        RouterModule,
        ReactiveFormsModule,
        NgOptimizedImage,

        AlertModule,
        FontAwesomeModule,
        InputPasswordModule,
        NgbControlModule,
        PanelModule,

        AppRouting,
    ],
    bootstrap: [AppComponent],
    providers: [
        {provide: ENVIRONMENT, useValue: environment},
        CookieService,
    ],
})
export class AppModule {}
